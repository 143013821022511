import React, { Component } from "react";
import { graphql } from "gatsby";
import { useLocation } from '@reach/router';
import ReactHtmlParser from 'react-html-parser';
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay, Thumbs, Mousewheel, Parallax } from 'swiper';
import "../css/style.css"
import Layout from "../components/layout";
import ProgramBannerUSAsection from "../components/program-banner-sec";
import ProgramOverviewCommon from "../components/program-overview";
import ProgramStructure from "../components/programstructure";
import PopupForm from "../components/popupForm";
import { Helmet } from "react-helmet";
import placeholder from "../images/accepted.jpg";
import CalendarDaysRegularIcon from '../images/icons/calendar-days-regular.svg';
import PassportIcon from '../images/icons/passport-solid.svg';
import BookOpenIcon from '../images/icons/book-open-solid.svg';
import DollarSignIcon from '../images/icons/dollar-sign-solid.svg';
import PlusSolidIcon from '../images/icons/plus-solid.svg';
import MinusSolidIcon from '../images/icons/minus-solid.svg';


Swiper.use([Navigation, Pagination, Autoplay, Scrollbar, Thumbs, Mousewheel, Parallax]);

class WorldgradProductTemplateCommon extends Component {

    constructor(props) {

        super(props);
        this.state = {
            time: 90000000,
            programUrl: props.path
        }
    }

    handlePopupWindow = () => {
        this.setState({ time: Math.random() })
    }

    componentDidMount() {


        
  // Check if there are any ul elements with class hide-item
  const hideItems = document.querySelectorAll('ul.hide-item');
        
  // If hideItems is not empty, show the "Read More" link
  if (hideItems.length > 0) {
      document.querySelector('a.showmore').style.display = 'block';

      // Add click event to the "Read More" link to toggle visibility of hidden items
      document.querySelector('a.showmore').addEventListener('click', (e) => {
        e.preventDefault(); // Prevent the default anchor behavior (scrolling up)
                
          hideItems.forEach(item => {
              item.classList.toggle('hide-item');
          });
          
          // Hide the "Read More" link after click
          document.querySelector('a.showmore').style.display = 'none';
          document.querySelector('a.showless').style.display = 'block';
      });

      document.querySelector('a.showless').addEventListener('click', (e) => {
        e.preventDefault(); // Prevent the default anchor behavior (scrolling up)
                
          hideItems.forEach(item => {
              item.classList.toggle('hide-item');
          });
          
          // Hide the "Read More" link after click
          document.querySelector('a.showless').style.display = 'none';
          document.querySelector('a.showmore').style.display = 'block';
      });

  }

        var swiper = new Swiper('.admission-swiper', {
            autoHeight: true,
            observer: true,
            observeParents: true,
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
                observer: true,
                observeParents: true,

            },
            breakpoints: {

                320: {

                    observer: true,
                    observeParents: true,
                },

                480: {

                    observer: true,
                    observeParents: true,
                },

                768: {

                    observer: true,
                    observeParents: true,
                }
            }
        });
    }

 



    render() {
        //const post = this.props.data.wordpressWpPogramlist;
       // const post = this.state.programData;
        const post = this.props.data.wordpressWpPogramlist;
        const pageURL =  `https://theworldgrad.com${this.state.programUrl}`;
       
        const metatitle = post.acf.meta_title;
        const metadescription = post.acf.meta_description;
        const focus_keyphrase = post.acf.focus_keyphrase;
        const canonical_url = post.acf.canonical_url;
       /* const countryCodes = {
            'Australia': 'AU',
            'United States of America': 'US',
            'Singapore': 'SG',
            'United Kingdom': 'UK'
          };

          const countryCode = countryCodes[post.acf.country] !== undefined ? countryCodes[post.acf.country] : post.acf.country;
          */

          const degreeType = post.acf.degree_type == "Undergraduate" ? "Bachelor's Degree" : post.acf.degree_type == "Postgraduate" ? "Master's Degree" : "NA"; 
   //console.log("post.acf.university_sponser_img->" + post.acf.degree_type);
          const hasCourseInstance = [];

         
          let courseMode = "Online"; 

          // Use a regular expression to match everything before the phrase "Click <a"
const match = post.acf.academic_requirments.match(/^(.*?)(?=Click\s<a)/);
const eligibilityText = match ? match[1].trim() : '';


          let courseWorkloadTWG = "";
          let courseWorkloadUniversity = "";

          switch (post.acf.term_1) {
            case "All American Undergraduate Program":
                courseWorkloadTWG = "P1Y";
                courseWorkloadUniversity = "P3Y";
                break;
            case "All American Graduate Program":
                courseWorkloadTWG = "P1Y";
                courseWorkloadUniversity = "P1Y";
                break;
            case "Global Online Accelerator Program":
                courseWorkloadTWG = "P6M";
                courseWorkloadUniversity = "P2Y6M";
                break;
            case "Global Year 1 Program":
                courseWorkloadTWG = "P1Y";
                courseWorkloadUniversity = "P2Y";
                break;
            default:
                courseWorkloadTWG = "NA";
                courseWorkloadUniversity = "NA";
                break;
        }
        
        if (post.acf.term_1_title.includes("Term 1")) {
            courseWorkloadTWG = "P6M";
        
            // Ensure that program_duration is defined and is a string before calling match
            const programDuration = post.acf.program_dudation;
            if (typeof programDuration === 'string') {
                const durationNumber = programDuration.match(/\d+(\.\d+)?/) ? parseFloat(programDuration.match(/\d+(\.\d+)?/)[0]) : null;
        
                if (durationNumber === 4 || durationNumber === 4.0) {
                    courseWorkloadUniversity = "P3Y6M";
                } else if (durationNumber === 3 || durationNumber === 3.0) {
                    courseWorkloadUniversity = "P2Y6M";
                } else if (durationNumber === 2 || durationNumber === 2.0) {
                    courseWorkloadUniversity = "P1Y6M";
                } else if (durationNumber === 1.5) {
                    courseWorkloadUniversity = "P1Y";
                }
            } else {
                //console.log('program_duration is not a string:', programDuration);
            }
        }
        //console.log("post.acf.university_sponser_img->"+ courseWorkloadUniversity +"--"+courseWorkloadTWG+"--"+post.acf.program_dudation)
          if (post.acf.term_1_title) {
           
            if (post.acf.term_1_title.includes("On-Campus")) {
                courseMode = "OnCampus";
            }
              hasCourseInstance.push({
                  "@type": "CourseInstance",
                  "courseMode": courseMode,
                  "courseWorkload": courseWorkloadTWG,
                  "location": {
                      "@type": "Place",
                      "name": post.acf.term_1,
                      "address": {
                          "@type": "PostalAddress",
                          "addressCountry": "IN"
                      }
                  }
              });
          }
          
     
       /*   

             // Add the third course instance if term_2_title and term_2 are available
             if (post.acf.term_2_title && post.acf.term_2) {
                courseMode = "Online"; // Default course mode
                if (post.acf.term_2_title.includes("On-Campus")) {
                    courseMode = "OnCampus";
                    hasCourseInstance.push({
                        "@type": "CourseInstance",
                        "courseMode": courseMode,
                       "courseWorkload": courseWorkloadTWG,
                       "location": {
                        "@type": "Place",
                        "name": post.acf.name_of_university,
                        "address": {
                            "@type": "PostalAddress",
                            "addressCountry": post.acf.country
                        }
                      }
                    });
                } else {
                    hasCourseInstance.push({
                        "@type": "CourseInstance",
                        "courseMode": courseMode,
                       "courseWorkload": courseWorkloadTWG,
                        "location": {
                            "@type": "Place",
                            "name": post.acf.term_2,
                            "address": {
                                "@type": "PostalAddress",
                                "addressCountry": "IN"
                            }
                        }
                    });
                }
               
            }

          */ 

            if (post.acf.term_3_title) {
              

                courseMode = "OnCampus"; // Default course mode
        
          hasCourseInstance.push({
              "@type": "CourseInstance",
              "courseMode": courseMode,
             "courseWorkload": courseWorkloadUniversity,
           
              "location": {
                  "@type": "Place",
                  "name": post.acf.name_of_university,
                  "address": {
                      "@type": "PostalAddress",
                      "addressCountry": post.acf.country
                  }
                }
          });
          
        }

          const hasCourseInstanceJson = JSON.stringify(hasCourseInstance, null, 2);

        const schema = `{
            "@context": "https://schema.org",
            "@type": "Course",
            "name": "${post.acf.meta_title}",
            "description": "${post.acf.meta_description}",
             "offers": [
    {
      "@type": "Offer",
      "category": "Paid"
    }
  ],
            "publisher": {
                  "@type": "Organization",
                  "name": "The WorldGrad",
                  "url": "https://theworldgrad.com"
                },
            "provider": {
              "@type": "CollegeOrUniversity",
              "name": "${post.acf.name_of_university}",
              "sameAs": "${post.acf.university_website_link}"
            },
            "image": [
                  "${post.featured_media}"
                ],
            "financialAidEligible": "Scholarship Available",
            "hasCourseInstance": ${hasCourseInstanceJson},
            "educationalCredentialAwarded": [{
                  "@type": "EducationalOccupationalCredential",
                  "name": "${degreeType}",
                  "credentialCategory": "Certificate"
                }],
            "url": "${pageURL}",
            "coursePrerequisites": ["${eligibilityText}", "https://lookerstudio.google.com/u/0/reporting/e37f852f-2b4c-419f-9211-ba96b5400c49/page/p_x7kzm0fkgd"]
          }`;
      //  const medianSalaryTitle = post.acf.median_salary_title;
        const employabilitySalary = post.acf.employability_median_salary;
        const indexOfBr = employabilitySalary.indexOf('<br/>');
        const bannerDetail = post.acf.banner_right_detail;

        // Replace the third node.banner_right_icon with custom image URL
        //bannerDetail[2].banner_right_icon = "https://assets.theworldgrad.com//wp-content/uploads/2024/02/visa.webp";
        
        // Replace the third node.banner_right_subtext with custom text
        bannerDetail[1].banner_right_text = "Hasslefree admissions to overseas campus";
        bannerDetail[1].banner_right_subtext = "";
        bannerDetail[2].banner_right_text = "Quick ROI with Post-Study Work Rights";
        bannerDetail[2].banner_right_subtext = "";
        //console.log("BannerDetail->"+JSON.stringify(bannerDetail))

        let contentBeforeBr = employabilitySalary;
        let contentAfterBr = '';

        if (indexOfBr !== -1) {
            contentBeforeBr = employabilitySalary.slice(0, indexOfBr);
            contentAfterBr = employabilitySalary.slice(indexOfBr + 5);
        }

        const styledAfterBr = contentAfterBr.replace(/\*(.*)/g, '<br/><span style="font-size: 12px">*$1</span>');


        // console.log("new post", post);

        return (
            <Layout>
                <Helmet>
                    <title>{metatitle}</title>
                    <meta name="description" content={metadescription} />
                    {(() => { if (post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) { return (<meta name="keywords" content={focus_keyphrase} />) } })()}
                    {(() => { if (post.acf.canonical_url != '' && post.acf.canonical_url != null) { return (<link rel="canonical" href={canonical_url} />) } })()}
 
                    <script type="application/ld+json">
                        
                        {JSON.stringify(JSON.parse(schema))}
                    
                    </script>
                    <link rel="icon" href="https://assets.theworldgrad.com//gatsby-assets/xtra/twg_favicon_ymgrbw.png" />
                </Helmet>

                <div class="newStyle font-poppins">
                    <PopupForm time={this.state.time} />
                    <ProgramBannerUSAsection
                                UniversityImage={post.featured_media?.source_url}
                                GraduationType={post.acf.degree_type}
                                CountryType={post.acf.country}
                                ProgramName={post.title.replace(/&#8211;/g, '-')}
                                ProgramDetail={post.content.replace(/&#8211;/g, '-')}
                                DegreeType={post.acf.degree_type}
                                NameOfUniversity={post.acf.name_of_university}
                                DegreeArea={post.acf.study_area}
                                ProgramDuration={post.acf.program_dudation}
                                Term1Info={post.acf.term_1}
                                Term2Info={post.acf.term_2}
                                Year3Info={post.acf.year_2_and_3}
                                programLink={post.acf.program_page_link_}
                                ProgramSponserdImage={post.acf.university_logo != null ? post.acf.university_logo : ""}
                                ProgramSponserdImage2={post.acf.university_sponser_img != null ? post.acf.university_sponser_img : ""}
                                // BannerRightIcon={post.acf.banner_right_detail.banner_right_icon.source_url}
                                // BannerRightText={post.acf.banner_right_detail.banner_right_text}
                                // BannerRightSubtext={post.acf.banner_right_detail.banner_right_subtext}
                                BannerRightDetail={post.acf.banner_right_detail != null ? post.acf.banner_right_detail : ""}
                                ProgramDurationTitle={post.acf.program_duration_title}
                                Term1Title={post.acf.term_1_title}
                                Term2Title={post.acf.term_2_title}
                                Term3Title={post.acf.term_3_title}
                                Term1option2={post.acf.term_1_option_2}
                                UniversityLink={post.acf.university_website_link}

                            />
                


                <section className="py-5" style={{ backgroundColor: "#fff" }}>
                    <div className="container">
                        <div className="row mx-0 justify-content-between university-detail-features-container px-0">
                            {post?.acf?.university_fees_title &&
                                (<div className="col-md-5 university-detail-features d-flex align-items-center">
                                    <div>
                                        <h5 class="font-weight-500">{post.acf.university_fees_title}</h5>
                                        <p> {post.acf.employability_university_fees} </p>
                                        <img
                                            className="university-detail-features-svg"
                                            src="https://assets.theworldgrad.com//gatsby-assets/xtra/Total-costsaving-1.png"
                                            alt="Location SVG"
                                        />
                                    </div>
                                </div>)
                            }
                            {post?.acf?.employability_median_salary &&
                                (<div className="col-md-5 university-detail-features d-flex align-items-center">
                                    <div>
                                        <h5 class="font-weight-500">{post.acf.median_salary_title}</h5>
                                        <p>{ReactHtmlParser(contentBeforeBr)}
                                            {styledAfterBr && <span>{ReactHtmlParser(styledAfterBr)}</span>}</p>
                                        <img
                                            className="university-detail-features-svg"
                                            src="https://assets.theworldgrad.com//gatsby-assets/xtra/money-bag.svg"
                                            alt="Location SVG"
                                        />
                                    </div>
                                </div>)
                            }
                            {post?.acf?.employability_job_prospects &&
                                (<div className="col-md-5 university-detail-features d-flex align-items-center">
                                    <div>
                                        <h5 class="font-weight-500">{post.acf.career_prospects_title}</h5>
                                        <p> {post.acf.employability_job_prospects}</p>
                                        <img
                                            className="university-detail-features-svg"
                                            src="https://assets.theworldgrad.com//gatsby-assets/xtra/Career-prospects-1.png"
                                            alt="Location SVG"
                                        />
                                    </div>
                                </div>)
                            }
                            {post?.acf?.post_study_work_rights_duration_title &&
                                (<div className="col-md-5 university-detail-features d-flex align-items-center">
                                    <div>
                                        <h5 class="font-weight-500">{post.acf.post_study_work_rights_duration_title}</h5>
                                        <p> {post.acf.employability_post_study_work_rights} </p>
                                        <img
                                            className="university-detail-features-svg"
                                            src="https://assets.theworldgrad.com//gatsby-assets/xtra/Post-Study-Work-Rights-Duration-duration-1.png"
                                            alt="Location SVG"
                                        />
                                    </div>
                                </div>)
                            }
                        </div>
                    </div>
                </section>
               
                    {post?.acf?.program_overview_title &&
                        (<section className="half-section bg-light-gray">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="progam-overview-box mb-0 last-paragraph-no-margin text-center">
                                            <h2 className="">{post.acf.program_overview_title}</h2>
                                            <p>{ReactHtmlParser(post.acf.program_overview.replace('<a href="#">Read More ↓</a>', ''))}</p>
                                            {/*post?.acf?.university_website_link && (
												<a data-animation="animated zoomInUp" class="btn btn-theme effect btn-md mt-3" href={post.acf.university_website_link} target="_blank">Know More</a>
											)*/}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>)
                    }


                    {post?.acf?.admissions_information_title &&
                        (<section class="half-section">
                            <div class="container">

                            <ProgramOverviewCommon
                                        QualificationTitle={post.acf.academic_requirements_title}
                                        QualificationInfo={post.acf.academic_requirments}
                                        EnglishRequirmentsTitle={post.acf.english_requirements_title}
                                        EnglishRequirments={post.acf.english_requirments}
                                        OtherRequirmentsTitle={post.acf.other_requirements_title}
                                        OtherRequirments={post.acf.other_requirments}
                                        OnlineFeesTitle={post.acf.fees_and_scholarships_title}
                                        OnlineFees={post.acf.online_fees}
                                        CampusFees={ReactHtmlParser(post.acf.offline_fees)}
                                        //AdmissionsInformationImage={post.acf.admissions_information_image.source_url != "" ? post.acf.admissions_information_image.source_url : "" }            
                                        AdmissionsInformationTitle={post.acf.admissions_information_title}
                                        AdmissionsInformationImage={post.acf.admissions_information_image != null ? post.acf.admissions_information_image.source_url : placeholder}
                                        OnlineFeesLabel={post.acf.online_fees_label}
                                        OfflineFeesLabel={post.acf.offline_fees_label}
                                        SavingsLabel={post.acf.savings_and_scholarships_title}
                                        SavingsText={post.acf.savings_and_scholarships}
                                        ScholarshipLabel={post.acf.scholarships_title}
                                        ScholarshipText={post.acf.scholarships_content}


                                    />
                            </div>
                        </section>)
                    }


                    {post?.acf?.program_structure_title &&
                        (<section class="half-section  bg-dark-gray loan-information  wow animate__fadeIn ">
                            <div class="container">
                                <div className="row">
                                    <div className="col-12">
                                        <h2 className="text-center">{post.acf.program_structure_title}</h2>
                                    </div>
                                </div>
                                <div class="row justify-content-center">
                                    <div class="col-12 col-lg-12 col-md-10 last-paragraph-no-margin">
                     
                                       <ProgramStructure
                                                Term1Info={post.acf.term_1}
                                                Stage1Title={post.acf.stage_1_title}
                                                Stage1Details={post.acf.stage1_details}
                                                Stage2Title={post.acf.stag2_title}
                                                Stage2Details={post.acf.stage2_details}
                                                UniversityName={post.acf.list_of_university}
                                                subjectInfo={post.acf.subject_information.map((eachBenefit, i) => (
                                                    <React.Fragment key={i}>
                                                        <ul className={`your-ul-class ${i < 4 ? '' : 'hide-item'}`}>
                                                            <li>
                                                                <p><strong>{i + 1}. </strong> {eachBenefit.subject_information}</p>
                                                            </li>
                                                        </ul>
                                                        {post.acf.subject_information.length > 4 && i === 3 && (
          
                                                            <a href="#" className="showmore btn btn-theme effect btn-sm uni-read-more-plus-btn">
                                                                <img src={PlusSolidIcon} alt="+" className="plusicon" />
                                                          
                                                        </a>
                                                        )}
                                                         {i === post.acf.subject_information.length - 1 && (
            <a href="#"  className="showless btn btn-theme effect btn-sm uni-read-more-plus-btn ">
              <img src={MinusSolidIcon} alt='-' className="plusicon" />
            </a>
          )} 
                                                        
                                                    </React.Fragment>
                                                ))}
                                                
                                                Term2Info={post.acf.term_2}
                                                Term3Info={post.acf.year_2_and_3}
                                            />
                                     
                                    </div>
                                    
                                </div>


                                {/* <LoanInformation /> */}
                                {post?.acf?.course_overview_link &&
                                    (<a data-animation="animated zoomInUp" class="btn btn-theme effect btn-md center-button-110 mt-3" href={post.acf.course_overview_link} target="_blank">Visit University Website</a>)
                                }
                            </div>

                        </section>)
                    }

                    {post?.acf?.admission_process_title &&
                        (<section class="half-section online-journey-inner">
                            <div class="container">
                                <div class="row justify-content-center">
                                    <div class="animate__slideInRight animated bg-cover br-15 col-12 col-lg-6 margin15 mt-5 mt-lg-0  mx-0 p-0 position-relative wow md-margin-50px-bottom" data-wow-delay="0.1s"  >
                                   
                                        <div class="swiper-container admission-swiper">
                                            <div class="swiper-wrapper">
                                                <div class="swiper-slide">
                                                    <div class="image-holder wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".1s">
                                                        
                                                        {post.acf.sample_offer_image !== null ?
                                                            (<img src={post.acf.sample_offer_image.source_url} alt={post.acf.name_of_university} class="img-fluid br-15 margin15" />
                                                            ) : (<img src={placeholder} alt={post.acf.name_of_university} class="img-fluid br-15 margin15" />
                                                            )
                                                        }
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>


                                    {post.acf.admissions_process !== null ?
                                        (<div class="col-12 col-xl-6 col-lg-6 col-md-10 ">
                                            <div class="col-12 p-0 margin-3-rem-bottom wow animate__slideInLeft" >
                                                <h2>{post.acf.admission_process_title}</h2>
                                            </div>
                                            <div class="col-12 p-0">

                                                {
                                                    post.acf.admissions_process.map((item, index) => {
                                                        return <div class="col-12 p-0 process-step-style-02 process-step-style-02-without-number  wow animate__fadeIn" data-wow-delay="0.2s" >
                                                            <div class="process-step-item">
                                                                <div class="process-step-icon-wrap">
                                                                    <div class="process-step-icon text-center border-all border-color-fast-blue border-width-2px bg-fast-blue alt-font font-weight- 500"></div>
                                                                    <span class="process-step-item-box-bfr bg-extra-dark-gray-2"></span>
                                                                </div>
                                                                <div class="process-content last-paragraph-no-margin">
                                                                    <span class="alt-font d-block font-weight-500 text-uppercase text-extra-dark-gray margin-5px-bottom">{item.process}</span>
                                                                    <div class="w-80 xs-w-100" dangerouslySetInnerHTML={{ __html: item.process_detail }} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })}

                                            </div>

                                        </div>) : ("")}
                                </div>
                            </div>
                        </section>)
                    }

                    

<section class="progam-overview-sec half-section bg-light-gray">
                            <div class="container">
                            <div class="row align-items-center">
                    <div class="col-12 col-lg-4 col-md-6 text-md-start sm-margin-30px-bottom wow animate__fadeIn">
                        <h2 class="">Click For More Information On</h2>
                    </div>
                    <div class="col-12 col-md-6 offset-lg-2 ps-lg-0 text-center wow animate__fadeIn">
                        <div class="row">
                        <div class="col-12 col-md-6 margin-10px-bottom text-center wow animate__fadeIn">
   <a class="btn btn-theme effect btn-md w-100" href="/admissions-process/">
      <img src={CalendarDaysRegularIcon} alt="Calendar Icon" className="left-icon newicon" /> Admissions Process
   </a>
</div>
<div class="col-12 col-md-6 margin-10px-bottom text-center wow animate__fadeIn">
   <a class="btn btn-theme effect btn-md w-100" href="/student-financing/">
      <img src={DollarSignIcon} alt="Dollar Sign Icon" className="left-icon newicon newicon-smallest top4px" /> Student Financing
   </a>
</div>
<div class="col-12 col-md-6 margin-10px-bottom text-center wow animate__fadeIn">
   <a class="btn btn-theme effect btn-md w-100" href="/study-resources/">
      <img src={BookOpenIcon} alt="Book Icon" className="left-icon newicon newicon-large top4px" /> Study Resources
   </a>
</div>
<div class="col-12 col-md-6 margin-10px-bottom text-center wow animate__fadeIn">
   <a class="btn btn-theme effect btn-md w-100" href="/visa-support/">
      <img src={PassportIcon} alt="Passport Icon" className="left-icon newicon" /> VISA Support
   </a>
</div>

                       </div>
                    </div>
                </div>
                            </div>
                        </section>

                </div>
            </Layout>
        )
    }

}

export default WorldgradProductTemplateCommon;


export const WorldgradProductsQuery = graphql`
query currentWorldgradProductCommonQuery( $id: String!){
    wordpressWpPogramlist(id: { eq: $id }) {
            title
            content
            type
            slug
            wordpress_id
            list_of_university
            name_of_university
            featured_media {
                source_url
            }
            acf {
            
                university_logo
                {
                    source_url
                }
                   academic_requirements_title
academic_requirments
english_requirements_title
english_requirments
other_requirements_title
other_requirments
fees_and_scholarships_title
online_fees
offline_fees
admissions_information_title
admissions_information_image {
                    source_url
                }
online_fees_label
offline_fees_label
savings_and_scholarships_title
savings_and_scholarships
scholarships_title
scholarships_content
                employability_job_prospects
                employability_median_salary
                employability_post_study_work_rights
                employability_university_fees
                course_overview_link
                academic_requirments
                program_page_link_
                university_sponser_img
                {
                  source_url
                }
                subject_information
                {
                    subject_information
                }
                study_area
                list_of_university
                name_of_university
                program_overview_title
                program_overview
                country
                degree_type
                academic_requirments
                english_requirments
                other_requirments
                program_dudation
                online_fees
                offline_fees
                list_of_university
                short_eligibility
                term_1
                term_1_option_2
                term_2
                university_ranking
                year_2_and_3
                you_save
                meta_title
meta_description
focus_keyphrase
canonical_url
dynamic_schema
                program_duration_title
                banner_right_detail {
                    banner_right_icon {
                    source_url
                    }
                    banner_right_text
                    banner_right_subtext
                }
                term_1_title
                term_2_title
                term_3_title
                university_fees_title
                employability_university_fees
                median_salary_title
                median_salary_title
                employability_median_salary
                career_prospects_title
                employability_job_prospects
                post_study_work_rights_duration_title
                employability_post_study_work_rights
                program_overview
                academic_requirments
                english_requirments
                university_ranking
                other_requirments
                online_fees_label
                online_fees
                offline_fees_label
                offline_fees
                stage_1_title
                stage1_details
                stag2_title
                stage2_details
                subject_information {
                    subject_information
                }
                admissions_information_title
                admission_process_title
                admissions_information_image {
                    source_url
                }
                sample_offer_image {
                    source_url
                }
                admissions_process {
                    process
                    process_detail
                }
                journey_title
                journey_subtitle
                journey {
                    journey
                    journey_detail
                }
                journey_image {
                    source_url
                }
                university_ranking
                study_area
                country
                short_eligibility
                you_save
 
                course_overview_link
                program_page_link_
                university_logo {
                    source_url
                }
                program_name_in_url
                short_eligibility
                program_structure_title
            }
          }
        }
`
